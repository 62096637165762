body {
    font-size: 15px;
    background-color: #f0f0f0;
    min-height: 100vh;
    font-family: Uber Move;
    color: #2c2c2c !important;
}

h1 {
    font-size: 42px;
    font-weight: bold;
}

h2 {
    font-size: 32px;
    font-weight: 600;
    color: #1e1f22;
}

a {
    text-decoration: none;
}

.ordersCard p a {
    color: #2c2c2c !important;
}

p a:hover {
    text-decoration: underline !important;
}

.navbar-light {
    background-color: #fff;
}


.navbar-brand {
    max-width: 250px;
}

.navbar-brand img {
    max-width: 100%;
}

.navbar-light .nav-link {
    color: #2c2c2c;
}

.navbar-nav .nav-link svg {
    transition: all ease 0.2s;
}

.navbar-nav .nav-link:hover svg {
    stroke: #e86d6d;
}

.navbar-nav .nav-link {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.navbar-nav .nav-link .badge {
    background-color: #ff6971 !important;
    right: 5px;
    font-size: 9px;
    top: 5px;
}

.userName .border-radius {
    border-radius: 50px;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    object-fit: cover;
    box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.form-input .form-control {
    border-radius: 20px !important;
    color: #bababa;
    font-size: 18px;
    padding: 8px;
    padding-left: 30px;
    font-weight: 600;
    box-shadow: none !important;
}

.input-group-text {
    padding: 10px;
    border: 0;
    border-radius: 50px !important;
    position: absolute;
    right: 6px;
    width: 40px;
    height: 40px;
    top: 3px;
    justify-content: center;
    background-color: transparent;
    z-index: 11;
}

.input-group-text svg {
    stroke: #bababa;
}

.form-input .form-control:focus {
    border-color: #000;
}

.form-input .form-control::-webkit-input-placeholder {
    color: #bababa;
}

.form-input .form-control:-ms-input-placeholder {
    color: #bababa;
}

.form-input .form-control::placeholder {
    color: #bababa;
}

.bannerImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px;
}

.card {
    border: 0;
    border-radius: 20px;
}

.breadcrumb a {
    color: #000;
}

.avtar-xl {
    border-radius: 50px;
    border: 2px solid #fff;
    width: 100px;
    height: 100px;
    box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
}

.text-gray {
    color: #a8a8a8;
}

.CardUls {
    list-style: none;
    padding-left: 0;
}

.CardUls li {
    padding: 5px 0;
}

.CardUls li svg {
    opacity: 0.7;
    margin-right: 7px;
}

.CardUls li a {
    transition: all ease 0.2s;
    color: #818181;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 3px 0;
    position: relative;

}

.CardUls li a:hover {
    color: #000;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #4bc84d;
    border-radius: 30px;
    position: absolute;
    left: 18px;
    top: 2px;
}

.card-header {
    background-color: transparent;
    color: #a22020;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 10px;
    border-color: #efefef;

}

.font-25 {
    font-size: 25px;
}

.card-headers h4 {

    color: #a22020;
    font-weight: bold;
    margin: 0;
    font-size: 18px;

}

.card-headers-new {
    padding: 20px 25px;
    background-color: #a22020;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.CardIns p {
    margin-bottom: 10px;
}

.border-radius-0 {
    border-radius: 0;
}

.card-title p {
    margin-bottom: 0;
}

.CardIns .card-img,
.CardIns .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 20px;
}


.img-cover {
    object-fit: cover;
}

.text-green {
    color: #a22020;
}

.text-hae {
    color: #a22020 !important;
}

.hr-2 {
    border-color: #efefef;
    opacity: 1;
}


.border-bottom-after {
    position: relative;
}

.border-bottom-after:after {
    border-bottom: 1px solid #f0f0f0;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -5px;
    content: "";
    width: auto;
    height: 0;
}

.memberList {
    list-style: none;
    padding-left: 0;
    max-height: 535px;
    overflow-y: auto;
    padding-right: 0;
    margin: 0;
    padding: 0;
    border: 0;
    margin-block-end: 0;
    margin-block-start: 0;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-left: calc(-.5 * var(--bs-gutter-x));
    margin-right: calc(-.5 * var(--bs-gutter-x));
}



.memberList::-webkit-scrollbar,
.maxHeight::-webkit-scrollbar {
    width: 8px;
}

.memberList::-webkit-scrollbar-track,
.maxHeight::-webkit-scrollbar-track {
    background-color: transparent;
}

.memberList::-webkit-scrollbar-thumb,
.maxHeight::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #929292;
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.memberList::-webkit-scrollbar-track-piece,
.maxHeight::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

.memberList::-webkit-scrollbar-thumb:vertical,
.maxHeight::-webkit-scrollbar-thumb:vertical {
    background-color: #929292;
    border-radius: 10px;
}

.memberListBorder {
    padding-right: 0;
}

.memberList .uderim {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
}

.memberList li a {
    transition: all ease 0.2s;
    color: #2c2c2c;
    padding: 8px 0;
    display: block;
}

.memberList li a:hover,
.memberList li a:focus,
.memberList li a:active,
.memberList li a.active {
    color: #a22020;
}



/* .red-color{
    color: red;
} */

.memberListBorder li {
    border-bottom: 1px solid #f0f0f0;
    padding: 7px 10px 7px 40px;
}

.memberListBorder1 li {
    border-bottom: 1px solid #f0f0f0;
}

.avatar-lg {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50px;
}

.HeartLike,
.HeartLikeFull {
    color: #898989;
}

.card-heights .card {
    height: 100%;
}

.card-headers-xl h4 {
    color: #a22020;
    font-weight: bold;
    margin: 0;
    font-size: 28px;
}


/* .HeartLike.active svg, .HeartLike.active svg path, .HeartLike:hover svg path  {
    fill: red;
} */


.HeartLikeFull svg .stroke {
    fill: #c5c5c5;
}

.HeartLikeFull svg .full {
    fill: transparent;
}

.HeartLikeFull.active svg path,
.HeartLikeFull:hover svg path {
    fill: red;
}

.sticky-top {
    position: sticky;
    top: 0;
}

.cardForum {
    color: #2c2c2c;
    text-align: center;
    position: relative;
    padding-top: 100%;
    display: block;
}

.cardForum-xl {
    width: 145px;
    height: 145px;
}

.cardForum-md {
    width: 80px;
    height: 80px;
}

.cardForumupper {
    color: #2c2c2c;
    text-align: center;
}

.cardForumupper:hover {
    color: #a22020;
}

.cardForumupper h6 {
    font-size: 14px;
    margin-top: 10px;
}

.cardForum img,
.cardForum video {
    border-radius: 20px;
    border: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.bannerMain {
    min-height: 100px;
}

.breadcrumb {
    padding-left: 5px;
}

.btn-round {
    border-radius: 10px;
    padding: 6px 15px;
}

.btn-black {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.btn-black:hover,
.btn-black:active,
.btn-black:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.modal-content {
    border-radius: 20px;
}

.row-minus-1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
}

.disbleForm label {
    pointer-events: none;
}

.disbleForm .form-control {
    pointer-events: none;
    border: 0;
    padding-left: 0;
    padding: 0;
    color: #a7a7a7;
}

.font-18 {
    font-size: 18px;
}

/* .font-normals *{font-size: 15px !important; line-height: normal !important;}
.text-underline{text-decoration: underline;} */

@media (max-width:767px) {
    .navbar-nav .nav-link {
        padding-left: 7px;
        padding-right: 7px;
    }

    .navbar-nav .nav-link svg {
        width: 22px;
    }

    .navbar-brand {
        max-width: 180px;
    }

    .userName .border-radius {
        width: 36px;
        height: 36px;
    }

    h1 {
        font-size: 34px;
    }

    .HeartLike svg,
    .HeartLikeFull svg {
        width: 22px;
        height: 22px;
    }

    .avatar-lg {
        width: 45px;
        height: 45px;
    }

    .memberList .uderim {
        width: 45px;
        height: 45px;
    }

    .avtar-xl {
        width: 80px;
        height: 80px;
    }

    .memberList {
        max-height: 400px;
    }
}

.FullImg {
    width: 100%;
    position: relative;
    padding-top: 80%;
}

.FullImg img, .FullImg video {
    border: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.dropdown-menu.show {
    display: block;
    transform: translate(-50%, 2%) !important;
}

.hover-link:hover {
    color: #008079 !important;
}

.maxHeight {
    overflow-y: auto;
    max-height: 600px;
}

.maxHeight500 {
    max-height: 500px;
    overflow-y: auto;
}

.maxHeight600 {
    max-height: 600px;
    overflow-y: auto;
}

.maxHeight700 {
    max-height: 795px;
    overflow-y: auto;
}

.maxHeightnone {
    max-height: 100%;
    overflow: initial;
}




.UplaodIm {
    position: relative;
    max-width: 60px;
    width: 60px;
    height: 60px;
}

.UplaodIm img {
    max-width: 100%;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    object-fit: cover;
}

.UplaodIm label {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UplaodIm input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
}

.UplaodIm svg {
    width: 50px;
    cursor: pointer;
}

.flex-1 {
    flex: 1;
}

.UplaodIm:hover svg,
.BtnSnd:hover svg {
    stroke: #a22020;
}

/* .card.card-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
} */

/* .card.card-radius {

    background-color: #f0f0f0;
    border: 0;
} */

.card.card-top-radius {
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    margin: 0 !important;
    padding: 0 !important;
}

.sticky-top {
    background-color: #fff;
    z-index: 1000;
}

.sticky-top-1 {
    background-color: #fff;
    top: -17px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 1px solid #f0f0f0;
}

.sticky-top-1 .card.card-top-radius {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
}

.sticky-top.card-top-radius .form-control {
    box-shadow: none !important;
    padding: 10px 20px;
    border-radius: 20px;
}

.sticky-top.card-top-radius .form-control:focus {
    border-color: #a22020;
}

header.navbar-light.sticky-top {
    z-index: 1001 !important;
}

.scrollWidth::-webkit-scrollbar {
    width: 0px;
}

.BtnSnd {
    background-color: transparent;
    border: 0;
}

#basic-navbar-nav .navbar-nav .nav-link {
    font-weight: bold;
    font-size: 18px;
}

#basic-navbar-nav .navbar-nav .nav-link:hover,
#basic-navbar-nav .navbar-nav .nav-link.active,
#basic-navbar-nav .navbar-nav .nav-link:focus,
#basic-navbar-nav .navbar-nav .nav-link:active {
    color: #a22020;
}

.ProfileImgs {
    width: 120px;
    position: relative;
}


@media (min-width:992px) {
    .navbar-nav.ms-auto {
        min-width: 250px;
    }
}

@media (max-width:991px) {
    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 0;
        background-color: #fff;
        width: 100%;
    }

    .navbar-toggler {
        border: 0 !important;
        box-shadow: none !important;
        margin-left: 15px;
    }

    .navbar-brand {
        padding: 0;
    }
}

.blink {
    animation: blinker .75s linear infinite;
    color: #f9f7f7;
    font-weight: bold;
    background: #a22020;
    border-radius: 6px;
    padding: 3px 7px;
    font-size: 12px;

}

.text-msg {
    color: #a22020;
}

.noblink {
    color: #f9f7f7;
    font-weight: bold;
    background: #a22020;
    border-radius: 6px;
    padding: 3px 7px;
    font-size: 12px;

}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.video-container {
    width: 100%;
    border: 1px solid black;
}

.video-wrapper {
    display: table;
    width: auto;
    position: relative;
    width: 100%;
}

.play-button {
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background-size: contain;
    background-position: center;
    background-color: #a22020;
    border-radius: 50px;
    padding: 7px;
    background-size: 25px;
    background-image: url('../public/assets/images/play-button-arrowhead.svg');
    /*here you can choose your picture for the play button*/

}





.rts___tabs___container .rts___btn {
    cursor: pointer;
    background-color: #fff;
    border-radius: 14px;
    border: 0;
    color: #a22020;
    padding: 13px 40px;
    font-size: 18px;
}

.rts___tabs___container .rts___tab___selected {
    background-color: #a22020;
    color: #fff;
    box-shadow: none;
}

.rts___nav___btn___container .rts___btn {
    padding: 8px 10px;
}

.rts___tabs___container .rts___tabs {
    display: flex;
    overflow: auto;
    padding: 0;
}

.rts___tabs___container .rts___nav___btn:hover {
    background-color: #a22020;
    transition: .5s all;
}




.card .card-title {
    padding: var(--bs-card-spacer-x);

}

.BtnBlk .atcb-button {
    background-color: #000 !important;
    color: #fff !important;
}


.ExperiencePage ol,
.ExperiencePage ul {
    padding-left: 18px;
    list-style-type: square;
}

.ExperiencePage p a,
.ExperiencePage p a span {
    color: #a22020 !important;

}

.ancorFont17 p a {
    font-size: 17px;
    text-decoration: underline !important;
}

.text-primary {
    color: #a22020 !important;
}

.ExperiencePage .btn-group .btn {
    background-color: transparent;
    color: #dee2e6 !important;
    border-top: 0;
    border-bottom: 0;
}

.ExperiencePage .btn-group .btn svg,
.ExperiencePage .btn-group .btn svg path {
    fill: #dee2e6 !important;
    stroke: #dee2e6 !important;
}


.ExperiencePage .btn-group .btn-success {
    color: #a22020 !important;
}

.ExperiencePage .btn-group .btn-success svg,
.ExperiencePage .btn-group .btn-success svg path {
    fill: #a22020 !important;
    stroke: #a22020 !important;
}



.ExperiencePage .btn-group .btn-dark {
    color: #2c2c2c !important;
}

.ExperiencePage .btn-group .btn-dark svg,
.ExperiencePage .btn-group .btn-dark svg path {
    fill: #2c2c2c !important;
    stroke: #2c2c2c !important;
}




.ExperiencePage .btn-group .btn-danger {
    color: #dc3545 !important;
    border-left-color: #2c2c2c;
}

.ExperiencePage .btn-group .btn-danger svg,
.ExperiencePage .btn-group .btn-danger svg path {
    fill: #dc3545 !important;
    stroke: #dc3545 !important;
}


.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 0;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
}


.CountDown {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    margin: auto;
    font-size: 20px;
    color: #fff;
}

.CountDown .CountDownSpan {
    background: #007A73;
    margin: 10px;
    padding: 40px 20px;
    border-radius: 20px;
    min-width: 140px;
}

.CountDown .FontTime {
    font-size: 40px;
    font-weight: bold;
}


.btn-green {
    background-color: #a22020;
    color: #fff;
    box-shadow: none;
}

.btn-green:hover,
.btn-green:active,
.btn-green:focus {
    background-color: #007A73;
    color: #fff;
    box-shadow: none;
}

.maxWidth600 {
    max-width: 600px;
    margin: 10px auto;
    border: 1px solid #ccc;
    padding: 20px 20px;
    border-radius: 20px;
}


.h-100vh {
    height: 100vh;
}


.loginpages label {
    margin-bottom: 5px;
}

.loginpages .form-control {
    padding: 9px 15px;
    font-size: 1rem;
    border: var(--bs-border-width) solid #9d9e9f;
    border-radius: 12px;
    box-shadow: none !important;
}

.loginpages .text-danger {
    font-size: 13px;
}

.rounded-20 {
    border-radius: 20px !important;
}
.rounded-10 {
    border-radius: 10px !important;
}
.closeico {
    width: 30px;
    padding: 9px;
    background-color: rgba(0,0,0,0.1);
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.2s;
}

.closeico:hover{
    background-color: rgba(0,0,0,0.3);
}
.closeico svg{
    width: 100%;
    height: 100%;
}

.ListTabs .nav-link img {
    width: 65px;
    height: 65px;
    min-width: 65px;
    object-fit: cover;
    border-radius: 10px;
}

.ListTabs .nav-link {
    background-color: #f0f0f0;
    margin-bottom: 15px;
    border-radius: 15px;
    color: #000;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #a22020;
}

.ListTabs .nav-link svg {
    width: 25px;
    height: 25px;
}

.nav-pills .nav-link.active svg,
.nav-pills .show>.nav-link svg {
    fill: #fff;
}

.ListTabs .tab-content {
    border: 1px solid #ccc;
    padding: 30px 50px;
}

.ListTabs .nav-link p {
    margin-bottom: 0;
}

.ListTabs .nav-link h6 {
    font-weight: 600;
    margin-bottom: 5px;
}

.CardIns .card-img-tops {
    border-radius: 0;
    height: 300px;
    object-fit: cover;
    object-position: top;
}

.font-14 {
    font-size: 14px;
    font-weight: 400;
}

.ListTabs .CardIns p {
    margin-bottom: 5px;
}


.btn-secondary {
    --bs-btn-color: #000;
    --bs-btn-bg: #f0f0f0;
    --bs-btn-border-color: #f0f0f0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f0f0f0;
    --bs-btn-disabled-border-color: #f0f0f0;
}
.btn-secondary:hover svg{fill: #fff;}


.UlsNext h5 ~ li {
    margin-left: 15px;
  }

  .UlsNext li {
    list-style: square;
  }

  .UlsNext h5 {
    font-weight: bold;
  }


  .UlsNext  {
    margin-bottom: 0;
  }

@media (max-width:767px) {
    .CountDown .CountDownSpan {
        padding: 40px 10px;
        min-width: inherit;
        max-width: calc(50% - 20px);
        width: 100%;
        flex: 0 0 calc(50% - 20px);
    }

    .CountDown {
        flex-wrap: wrap;
    }
}